/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import EditorJS from "@editorjs/editorjs";
// import Header from "@editorjs/header";
// import List from "@editorjs/list";
// import Paragraph from "@editorjs/paragraph";
// import Link from "@editorjs/link";
// import Quote from "@editorjs/quote";
// import Image from "@editorjs/image";
// import SimpleImage from "@editorjs/simple-image";
// import History from "@editorjs/";
require("@rails/activestorage").start();

import "controllers";
import "stylesheets/application";
let icons = Quill.import("ui/icons");

let toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],
  [{ 'direction': 'rtl' }],
  [{ 'header': [1, 2, 3, false] }],
  [{ 'align': [] }],
  ['link'],
  ['clean'],
  ['undo', 'redo'],
];
let formats = [
  'bold',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  //'formula',
  // 'image',
  // 'video'
];
icons["undo"] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon><path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>`;
icons["redo"] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon><path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>`;
let options = {
  placeholder: 'Yazınızı buraya yazabilirsiniz...',
  modules: {
    toolbar: {
      container: toolbarOptions,
      handlers: {
        undo: function(value) {
          if(!this.quill.history.lastRecorded){
            this.quill.root.innerHTML = window.localStorage.getItem('quill');
          }else{
            this.quill.history.undo();
          }
        },
        redo: function(value) {
          this.quill.history.redo();
        }
      }
    },
    history: {
      delay: 2000,
      maxStack: 500
    },
  },
  theme: 'snow',
  formats: formats,
};


if (document.getElementById('editor')) {
  const quill = new Quill("#editor", options);

  quill.clipboard.addMatcher(Node.ELEMENT_NODE, (_node, delta) => {
    let ops = []
    delta.ops.forEach(op => {
      if (op.insert && typeof op.insert === 'string') {
        ops.push({
          insert: op.insert
        })
      }
    })
    delta.ops = ops
    return delta
  })

  function mirrorQuill() {
    document.getElementById('post_body').value = quill.root.innerHTML;
    if(quill.root.innerHTML !== "<p><br></p>") {
      window.localStorage.setItem('quill', quill.root.innerHTML);
    }
  }

  quill.on("text-change", mirrorQuill);
  mirrorQuill();
}

window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      console.log('ServiceWorker registered: ', registration)
    });
});
